div.main-image-carousel {
    height: 85vh;
}

@media screen and (max-width: 2499px) {
    div.main-image-carousel {
        height: 75vh;
    }
}

@media screen and (max-height: 1155px) {
    div.main-image-carousel {
        height: 860px;
    }
}

@media screen and (max-width: 600px) {
    div.main-image-carousel {
        height: 520px;
    }
    div.image-carousel-caption.rounded.mt-3.p-1.package-text-color-basic.carousel-caption.d-none.d-md-block {
        display: block !important;
    }
}