.with-bg {
    background-image: url("/public/images/app-bg-rs.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.selected-package-view-col {
    border-radius: 8px 8px;
    min-height: 6rem;
}

.selected-package-view-body-wrapper {
    min-height: 32rem;
}

.selected-package-view-items-wrapper {
    min-height: 32rem;
}

.selected-package-view-service-item {
    min-height: 9rem;
}

.all-previous-services-basic {
    color: lightslategrey;
}

.all-previous-services-pro {
    color: #FFA41B;
}

.all-previous-services-business {
    color: #00A8CC;
}

.all-previous-services-business-plus {
    color: #005082;
}