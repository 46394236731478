#root > nav > nav.navbar-is-open {
  height: 100vh;
  transition: 1s ease-in-out;
  align-items: flex-start;
}

#root > nav > nav > div > div > ul.nav-link-wrapper {
  transition: 1s ease-in-out;
}

#root > nav > nav > div > div > ul.nav-link-wrapper-is-open {
  transition: 1s ease-in-out;
}