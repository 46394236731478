.bg-ec-1 {
    background-color: #0c75BC !important;
}

.bg-ec-2 {
    background-color: #049DD7 !important;
}

.bg-ec-3 {
    background-color: #015CA2 !important;
}

.color-ec-3 {
    color: #015CA2 !important;
}

.card-bg-free,
.card-bg-detailed-free,
.diagonal-bg-free {
    background-color: lightslategrey;
}

.card-bg-basic,
.card-bg-detailed-basic,
.diagonal-bg-basic {
    background-color: #FFA41B;
}

.card-bg-pro,
.card-bg-detailed-pro,
.diagonal-bg-pro {
    background-color: #00A8CC;
}

.card-bg-business,
.card-bg-detailed-business,
.diagonal-bg-business {
    background-color: #005082;
}

.card-bg-business-plus,
.card-bg-detailed-business-plus,
.diagonal-bg-business-plus {
    background-color: #000839;
}

div.main-content-wrapper>h3,
div.main-content-wrapper>h4,
div.main-content-wrapper>h2,
h1.main-content-header-color,
h2.main-content-header-color,
h3.main-content-header-color,
h5.main-content-header-color {
    color: #005082;
}

button.main-content-bg-color {
    background-color: #005082;
}
div.package-wrapper {
    display: flex;
    justify-content: center;
    border: 4px solid lightslategrey;
    border-radius: 20px 20px 20px 20px !important;
}

.package-text-color-free,
div.package-text-color-free,
h1.package-text-color-free {
    color: lightslategrey;
}

.package-text-color-basic,
div.package-text-color-basic,
h1.package-text-color-basic {
    color: #FFA41B;
}

.package-text-color-pro,
h1.package-text-color-pro {
    color: #00A8CC;
}

.price-business,
.package-text-color-business,
h1.package-text-color-business {
    color: #005082;
}

.price-business-plus,
.package-text-color-business-plus,
h1.package-text-color-business-plus {
    color: #000839;
}

p.price-container {
    font-size: 1.6rem;
    font-weight: bold;
}

.hidden-hoverable-content {
    visibility: hidden;
}

.show-hovered-content {
    display: block;
}

.hoverable-item:hover {
    cursor: pointer;
}

.carousel-caption.image-carousel-caption {
    position: relative;
    top: -20;
    left: 0;
}

.carousel-caption.slider-image-carousel-caption {
    position: relative;
    bottom: 0rem;
    left: 0;
}

.carousel-control-prev,
.carousel-control-next {
    filter: invert(100%);
}

a.color-ec-3.package-link {
    text-decoration: none;
}

a.color-ec-3.package-link,
a.package-link {
    text-decoration: none;
}

a.package-link {
    color: black;
}

a.package-link:hover {
    color: #015CA2;
}

ul.packages-and-prices-service-container.list-group>li.packages-teaser-service-item.list-group-item {
    min-height: 5rem;
}

ul.modules-and-prices-service-container.list-group>li.packages-teaser-service-item.list-group-item {
    min-height: 5rem;
}

ul>li.dropdown.show.nav-item>div>button:hover {
    background-color: lightslategrey;
}

.grecaptcha-badge {
    visibility: hidden;
}

hr.app-horizontal-ruler {
    margin: 2rem 20% 2rem 20%;
}

ul.nav.flex-column > li.nav-item a {
    color: white;
}

ul.nav.flex-column > li.nav-item a:hover {
    color: grey;
}


